import { lighten } from 'color2k';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScheduleMembers } from '@/pages/Schedule/hooks';
import type { DecoratedMessage } from '@/pages/Schedule/types';
import { summarizeMessageSendDate } from '@/pages/Schedule/utils';
import { MessageStatusType } from '@/types/gql.generated';
import {
  Icons,
  type IconType,
  MotionFlex,
  Box,
  Flex,
  IconButton as ChakraIconButton,
  Icon,
  type IconButtonProps,
  Text,
  useToken,
  Button,
  type ButtonProps,
} from '@/ui';

type Props = {
  message: DecoratedMessage;
  isDeletingMessage?: boolean;
  onEditMessage?: (message: DecoratedMessage) => void;
  onDeleteMessage?: (message: DecoratedMessage) => void;
};

export const MessageBubble = ({
  message,
  isDeletingMessage,
  onEditMessage,
  onDeleteMessage,
}: Props) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const { t } = useTranslation('messageBubble');
  const { members } = useScheduleMembers();

  const isMessagePersisted = message.status !== null;

  const canModifyMessage =
    !isMessagePersisted || message.status === MessageStatusType.Scheduled;

  const recipientNames =
    message.recipients.length === 0
      ? t('everyone')
      : message.recipients
          .map(({ id }) => {
            return (
              members.find((member) => member.id === id)?.name ??
              t('unknown_member')
            );
          })
          .join(', ');

  return (
    <Flex
      data-testid={`message-${message.id}`}
      direction="column"
      gap="1.5"
      opacity={message.status === MessageStatusType.Sent ? 0.6 : 1}
    >
      <Flex
        bg="customgray.dark"
        borderBottomRightRadius="0"
        borderRadius="16px"
        color="customgray.gray"
        flex="1"
        p="4"
      >
        <Box flex="1" overflowWrap="anywhere" whiteSpace="pre-wrap">
          {message.body}
        </Box>
        {canModifyMessage ? (
          <Flex gap="1" pos="relative">
            {onEditMessage && (
              <IconButton
                aria-label={t('edit_message')}
                icon={Icons.Pencil}
                onClick={() => onEditMessage?.(message)}
              />
            )}
            {isConfirmingDelete && (
              <MotionFlex
                animate={{ opacity: 1 }}
                bg="customgray.dark"
                borderRadius="full"
                gap="1"
                initial={{ opacity: 0 }}
                mr="-2"
                mt="-2"
                p="2"
                pos="absolute"
                right="0"
                shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.3)"
                transition={{ duration: 0.1 }}
                zIndex="1"
              >
                <ConfirmButton
                  isLoading={isDeletingMessage}
                  onClick={() => onDeleteMessage?.(message)}
                >
                  {t('delete_confirm')}
                </ConfirmButton>
                <ConfirmButton onClick={() => setIsConfirmingDelete(false)}>
                  {t('delete_cancel')}
                </ConfirmButton>
              </MotionFlex>
            )}
            {onDeleteMessage && (
              <IconButton
                aria-label={t('delete_message')}
                icon={Icons.Trash}
                onClick={() => setIsConfirmingDelete(true)}
              />
            )}
          </Flex>
        ) : isMessagePersisted ? (
          <Text color="customgray.mid" fontSize="xs" fontWeight="bold">
            {message.status}
          </Text>
        ) : null}
      </Flex>
      <Flex align="center" color="customgray.mid" fontSize="xs" gap="5" px="4">
        <Flex align="center" gap="2" overflow="hidden">
          <Icon icon="Clock" size="3" />
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {summarizeMessageSendDate(message)}
          </Box>
        </Flex>
        <Flex align="center" gap="2">
          <Icon icon="User" size="3" />
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {recipientNames}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

const IconButton = ({
  icon,
  ...props
}: Omit<IconButtonProps, 'icon'> & { icon: IconType }) => {
  const darkerGray = useToken('colors', 'customgray.dark');

  return (
    <ChakraIconButton
      _active={{ bg: lighten(darkerGray, 0.2) }}
      _hover={{ bg: lighten(darkerGray, 0.1) }}
      data-component-name="ChakraIconButton"
      size="xs"
      variant="ghost"
      icon={
        <Icon
          as={icon}
          boxSize="16px"
          color="customgray.mid"
          sx={{
            // avoiding _groupHover so hover styles aren't activated when an
            // ancestor group receives hover
            '[data-component-name="ChakraIconButton"]:hover &': {
              color: 'customgray.gray',
            },
          }}
        />
      }
      {...props}
    />
  );
};

const ConfirmButton = (props: ButtonProps) => {
  const darkerGray = useToken('colors', 'customgray.dark');

  return (
    <Button
      _active={{ bg: lighten(darkerGray, 0.2) }}
      _hover={{ bg: lighten(darkerGray, 0.15) }}
      bg={lighten(darkerGray, 0.1)}
      color="white"
      size="xs"
      variant="ghost"
      {...props}
    />
  );
};
